/*==========  GENERIC  ==========*/
.centered {
  text-align: center;
}
.text-right {
  text-align:right;
}
.clear{
  clear:both;
}

.float-right{
  float:right;
}

.float-left{
  float:left;
}

.no-padding.no-padding {
  padding:0;
}

.block {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.margin-top-medium {
  margin-top: 15px;
}

.margin-bottom-medium {
  margin-bottom:  15px;
}

.margin-top-large {
  margin-top: 30px;
}

.margin-bottom-large {
  margin-bottom:  30px;
}

.align-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.margin-left-small {
  margin-left: 20px;
}

.margin-right-small {
  margin-right: 20px;
}

/* Provides a medium space under the element bearing this class.  Used to standardise spacing across all content */
.margin-bottom-medium {
  margin-bottom:  15px;
}

/* Adds a margin to the top of any element.  Can be applied to any visible element to give a consistent appearance with standardised spacing between content */
.margin-top-medium {
  margin-top: 15px;
}

.img{
  &--circle {
    border-radius:50%;
  }
}

/*  Z-INDEXes - i-escape and vuetify sometimes uses incorrect z-indexes, here we can overwrite them   */
//.menu__content--select {
//  z-index:300!important;
//}
