
/* Vue app - Default to block to ensure other content such as footers don't get misplaced */
.application {
    display: block !important;
}

/* Vue Content Panel - Default to 100% of the page */
#app-content {
    width: 100%;
}

/* Fonts */
@font-face {
font-family: 'cabrito_norm_regularnormRg';
    src: url('/fonts/CabritoNormRegular-webfont.eot');
    src: url('/fonts/CabritoNormRegular-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CabritoNormRegular-webfont.woff2') format('woff2'),
        url('/fonts/CabritoNormRegular-webfont.woff') format('woff'),
        url('/fonts/CabritoNormRegular-webfont.ttf') format('truetype'),
        url('/fonts/CabritoNormRegular-webfont.svg#cabrito_norm_regularnormRg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
font-family: 'cabrito_norm_deminorm_demi';
    src: url('/fonts/CabritoNormDemi-webfont.eot');
    src: url('/fonts/CabritoNormDemi-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CabritoNormDemi-webfont.woff2') format('woff2'),
        url('/fonts/CabritoNormDemi-webfont.woff') format('woff'),
        url('/fonts/CabritoNormDemi-webfont.ttf') format('truetype'),
        url('/fonts/CabritoNormDemi-webfont.svg#cabrito_norm_deminorm_demi') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cabrito_sans_norm_regulnormRg';
    src: url('/fonts/CabritoSansNormRegular-webfont.eot');
    src: url('/fonts/CabritoSansNormRegular-webfont.eot?#iefix') format('embedded-opentype'),
        url('/fonts/CabritoSansNormRegular-webfont.woff2') format('woff2'),
        url('/fonts/CabritoSansNormRegular-webfont.woff') format('woff'),
        url('/fonts/CabritoSansNormRegular-webfont.ttf') format('truetype'),
        url('/fonts/CabritoSansNormRegular-webfont.svg#cabrito_sans_norm_regulnormRg') format('svg');
    font-weight: normal;
    font-style: normal;
}
[v-cloak] {
    display: none;
}

/* Default Links and Anchors */
a, a:link, a:visited, a:hover, a:active,
.v-application a, .v-application a:link, .v-application a:hover, .v-application a:active
{
    color: $brand-primary !important;
}

a.v-btn, a.v-btn:link, a.v-btn:visited, a.v-btn:hover, a.v-btn:active,
.v-application a.v-btn, .v-application a.v-btn:link, .v-application a.v-btn:hover, .v-application a.v-btn:active
{
    color: black;
}


/* Display */

.hidden {
    display: none;
}

/* Headings */
nav {
    color:      rgb(0,0,0);
    position:   absolute;
    z-index:    10;
    background-color: rgb(255,255,255);
}

#sideNav {
    position:   fixed;
    background-color: rgb(255,255,255);
    z-index:    1;
    min-width:  300px;

    /* List of menu options within the navigation */
    .list {
        background-color: rgb(255,255,255);
    }

    /* Links within the navigation menu */
    a.list__item {
        color: rgb(0,0,0);
    }
}

/* Headings */
h1,h2,h3,h4,h5,h6 {
//    font-family:"cabrito_norm_deminorm_demi",serif;*/
}

h1 {
    font-size: 2.2rem;
    @include up-to-medium{
        font-size: 1.75rem;
    }
}

h2, h3, h4 {
    font-size: 1.5rem;
}

label, legend {
    font-weight: bold;
    font-family: sans-serif;
}

/* Tables */
thead {
    border-bottom: solid 1px rgb(242, 242, 242);
}
tbody tr {
    border-bottom: solid 1px rgb(242, 242, 242);
}


/* Generic classes */
@media print {
    /* Items which have no value when printed */
    .no-print {
        display: none !important;
    }

    /* Turn off Vue's default aesthetic styling from common classes and components for clearer printouts */
    .card {
        border: none !important;
        box-shadow: none;
    }
}


.navbar-logo {
    margin-top: 1rem;
    max-width:  100%;
    max-height: 50px;
}


/* Width */
.full-width {
    width:100%;
}

.half-width{
    width:50%;
}

.full-height {
    height:100%;
}


/* Common containers */
.v-align-container{
    display: table;

    .v-align-div {
        display:table-cell;
        vertical-align:middle 
    }
}

.v-card__title, .button-bar{
    background-color: rgb(242, 242, 242);
}
.menu__content{
   z-index:2;
}


/* Padding */
.mobile-no-padding{
  @include up-to-medium{
    padding:0px;
  }
}

.no-padding{
    padding:0px;
}


/* Alignment */
.float-left{
    float:left;
}
.float-right{
    float:right;
}

.no-min-height{
    min-height:none;
    .input-group__details{
        min-height:none;
    }

}

/* Margin */
.margin-top-small {
    margin-top: 1rem;
}
.margin-top-medium {
    margin-top: 2rem;
}
.margin-top-large {
    margin-top: 4rem;
}

.margin-bottom-small {
    margin-bottom: 1rem;
}
.margin-bottom-medium{
    margin-bottom: 2rem;
}
.margin-bottom-large {
    margin-bottom: 4rem;
}

.block {
    display: block;
}


/* Formatting */
.strong {
    font-weight: bold;
}

/* Prevent overflow on any element */
.no-wrap {
    overflow: hidden;
}

/* The default Vuetify is not always appropriate - these override the defaults */
table.table thead td:not(:nth-child(1)),
table.table tbody td:not(:nth-child(1)),
table.table thead th:not(:nth-child(1)),
table.table tbody th:not(:nth-child(1)),
table.table thead td:first-child,
table.table tbody td:first-child,
table.table thead th:first-child,
table.table tbody th:first-child {
    padding: 0 1rem;
}

#linearWrapper {
    height: 7px;

    .progress-linear {
        margin: 0;
    }
}


/* Vuetify alerts */
.warning {
    color:  rgb(0,0,0);
}


/**/ 
.btn, .v-btn {
    text-transform: none !important;
}

/* The v-date-picker component's prev and next month chevrons are not displaying after upgrading Vue so this is a quick jury rig workaround to get it showing immediately to be fixed properly at a much later date */
.v-date-picker-header .v-btn i.mdi-chevron-left::after {
    content: "<";
    opacity: 1;
    background-color: white;
    text-decoration: none;
    font-family: sans-serif;
    color: black;
    font-size: 1rem;
}
.v-date-picker-header .v-btn i.mdi-chevron-right::after {
    content: ">";
    opacity: 1;
    background-color: white;
    text-decoration: none;
    font-family: sans-serif;
    color: black;
    font-size: 1rem;
}

.menuable__content__active {
    margin-top: 50px !important;
}

.record-history {
    
}

/* Vuetify has stopped showing input components, so we need to force it to remain visible */
.v-input--selection-controls__input input[role=checkbox], .v-input--selection-controls__input input[role=radio]/*, .v-input--selection-controls__input input[role=switch]*/
{
    opacity: 1 !important;
}

textarea {
    border: solid 1px lightgray;
    border-style: solid;
}
