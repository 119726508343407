.ies-green {
    background-color: $brand-primary !important;
    &--text {
        &--after {
            &::after {
            color: $brand-primary !important;
            }
        }
        color: $brand-primary !important;
    }

    &--after {
        &::after {
            background: $brand-primary !important;
        }
    }
}
.ies-blue {
    background-color: $brand-info !important;
    &--text {
        &--after {
            &::after {
                color: $brand-info !important;
            }
        }

        color: $brand-info !important;
    }

    &--after {
        &::after {
            background: $brand-info !important;
        }
    }
}
.ies-coral {
    background-color: $brand-warning !important;
    &--text {
        &--after {
            ::after {
                color: $brand-warning !important;
            }
        }

        color: $brand-warning !important;
    }

    &--after {
        &::after {
            background: $brand-warning !important;
        }
    }
}
.ies-light-gray {
    background-color: $brand-light-gray !important;
    &--text {
        &--after {
            ::after {
              color: $brand-light-gray !important;
            }
        }

        color: $brand-light-gray !important;
    }

    &--after {
        &::after {
            background: $brand-light-gray !important;
        }
    }
}
.ies-dark-gray {
    background-color: $brand-dark-gray !important;
    &--text {
        &--after {
            &::after {
                color: $brand-dark-gray !important;
            }
        }

        color: $brand-dark-gray !important;
    }

    &--after {
        &::after {
            background: $brand-dark-gray !important;
        }
    }
}

