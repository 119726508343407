fieldset {
    padding: 1rem;
}

legend {
    padding: 1rem;
}

// label {
//     font-weight: bold;
//     line-height: 25px;
//     padding: 5px 5px 5px 0px;
// }

// input {
//     &[type="text"], &[type="password"], &[type="number"], &[type="email"], &[type="date"], &[type="time"], &[type="datetime"] {
//         border:solid 1px  $brand-dark-gray; 
//         line-height: 25px;
//         padding: 5px 5px 5px 5px;
//         width:100%;
//         &:focus {
//             outline:0;
//             box-shadow:0 0 5px $brand-primary;
//         }
//     }
// }

select {
    -moz-appearance: button;
    -webkit-appearance: button;
    border: solid 1px $brand-dark-gray;
    padding: 0.3rem;
}

textarea {
    border:solid 1px  $brand-dark-gray;
    line-height: 25px;
    padding: 5px 5px 5px 5px;
    width:100%;
	height: 100px;
}

textarea[id*="tinymce_"] {
	height: 15rem;
}

.checkbox {
	label {
		vertical-align: top;
	}
	input {
		width: 22px;
		height: 22px;
	}
}

textarea[id*="tinymce_"] {
	height: 15rem;
}

button, a.btn {
    color: rgb(0,0,0);
}

.btn--disabled {
    color: $brand-dark-gray;
}

table .date {
	text-align: left;
}

/* If this input contains an error needing attention */
.error-field{
    border: solid 1px $brand-warning !important;
    color: $brand-warning;
}

.field-container .container {
    padding: 0;
    margin-bottom:  0.5rem;
}



/* Styling for printouts */
@media print {
    input, textarea {
        border: none !important;
    }

    button {
        display: none !important;
    }
}


.permission-list {
    // variable colors
    $ReadWrite:#68e2be;
    $Read:#eabb75;

    table {
        border: 1px solid #ccc;
        border-collapse: collapse;
        margin: 0;
        padding: 0 !important;
        width: 100%;
        table-layout: fixed;
        text-align: center;
    }

    table tr {
        border: none;
	border-bottom: none;
    }
    .nameColumn {
        width: 200px;
    }

    .emptyColumn {
        width: 60px;
    }

    .headUser {
        background-color: $brand-info;
        color: white;
        font-weight: bold;
        height: 30px;
    }

    tr:hover td {
        background: #e8e5e5;
    }

    tr:hover td:first-child {
        font-weight: bold;
    }

    .optionRead {
        background-color: $Read;
    }

    .optionReadWrite {
        background-color: $ReadWrite;
    }

    select {
        width: 100%;
        text-align-last:center;
        border: none;
    }

}

