@mixin media-query($media-query) {
    @if $media-query == tiny {
        @media only screen and (max-width: $tiny) {
            @content;
        }
    }

    @if $media-query == small {
        @media only screen and (min-width: $small) {
            @content;
        }
    }

    @if $media-query == small-only {
        @media only screen and (min-width: $small) and (max-width: $medium - 1) {
            @content;
        }
    }

    @if $media-query == up-to-medium {
        @media only screen and (max-width: $medium - 1) {
            @content;
        }
    }

    @if $media-query == medium {
        @media only screen and (min-width: $medium) {
            @content;
        }
    }

    @if $media-query == large {
        @media only screen and (min-width: $large) {
            @content;
        }
    }

    @if $media-query == xlarge {
        @media only screen and (min-width: $large+1) {
            @content;
        }
    }
    @if $media-query == landscape {
        @media only screen and (orientation : landscape) {
            @content;
        }
    }
    @if $media-query == portrait {
        @media only screen and (orientation : portrait) {
            @content;
        }
    }
}

// MEDIA QUERY HELPERS
@mixin tiny {
    @include media-query(tiny) {
        @content
    };
}

@mixin mob-nav {
    @include media-query(mob-nav) {
        @content
    };
}

@mixin search-dropdown {
    @include media-query(search-dropdown) {
        @content
    };
}

@mixin small {
    @include media-query(small) {
        @content
    };
}

@mixin small-only {
    @include media-query(small-only) {
        @content
    };
}

@mixin up-to-medium {
    @include media-query(up-to-medium) {
        @content
    };
}

@mixin medium {
    @include media-query(medium) {
        @content
    };
}

@mixin large {
    @include media-query(large) {
        @content
    };
}

@mixin xlarge {
    @include media-query(xlarge) {
        @content
    };
}

@mixin landscape {
    @include media-query(landscape) {
        @content
    };
}
@mixin portrait {
    @include media-query(portrait) {
        @content
    };
}
