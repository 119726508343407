.footer {
    display:block;
    margin-top: 2rem;
    background-color:$brand-light-gray;
    min-height: 10rem;
    padding:    2rem;
    color:      $brand-dark-gray;
    text-align: center;

    &--menu {
        color: $brand-dark-gray;
        width:   100%;
        padding: 0.5rem;
        margin:  auto;
        text-align: center;

        a {
            color:$brand-dark-gray;
            &:hover{
                color:$brand-primary;
            }
        }
    }


}
